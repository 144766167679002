import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import ShowMoreText from 'react-show-more-text';
import axios from "axios";
import { Col } from 'react-bootstrap';
import ImgStar from "../../images/icon-star.svg";
import Grate from "../../images/google-review-logo.svg";

// Style
import './Reviews.scss';
import { Helmet } from "react-helmet";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const postsPerPage = 20;
let arrayForHoldingPosts = [];

const Reviews = (props) => {

  const [] = useState(true);
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const [postsToShow, setPostsToShow] = useState([]);

  const [] = useState(true);
  const [testimonials, setTestimonials] = useState([])

  const getitems = async url => {
    try {
      const { data } = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`
        }               
      })// could be from env files
      setTestimonials(data?.filter(list => list.name.toLowerCase().includes(props.ReviewId)))
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }

  const loopWithSlice = (start, end) => {
    const slicedPosts = testimonials && testimonials.slice(start, end);
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
    setPostsToShow(arrayForHoldingPosts);
  };

  useEffect(() => {
    loopWithSlice(0, postsPerPage);
  }, [testimonials]);

  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    getitems(url);
  }, [])

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsThumbs = {
    dots: false,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: false,
    asNavFor: '.slider-for',
    focusOnSelect: true,
    infinite: false,
  }

  return (
    <>
      <section class="google-review-section review-blogs">

        <Helmet>
          <script type="application/ld+json">{`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Orlando Reid",
            "alternateName": "Orlando Reid",
            "url": "https://www.orlandoreid.co.uk/",
            "logo": "https://ggfx-orlandoreid.s3.eu-west-2.amazonaws.com/i.prod/OR_Logo_115effa5b8.jpg",
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "0207 627 3197",
              "areaServed": "GB",
              "availableLanguage": "en"
            },
                "aggregateRating": {
                  "@type": "AggregateRating",
                  "ratingValue": "4.7",
                  "reviewCount": "327"
                },
            "sameAs": [
              "https://www.facebook.com/pages/Orlando-Reid/163694923694097",
              "https://twitter.com/OrlandoReidUK",
              "https://www.instagram.com/orlandoreidlondon/?hl=en",
              "https://www.linkedin.com/company/orlando-reid/"
            ]
          }`}</script>
        </Helmet>

        <div class="container">
          <h2>{props.ReviewTitle}</h2>
          <Slider {...settings} asNavFor={nav2} ref={slider => (setSlider1(slider))}>
            {postsToShow && postsToShow.map((review) => {
              if (review.starRating != "ONE" && review.starRating != "TWO" && review.starRating != "THREE") {
                return <>
                  <Col md={12} className="myList">
                    <div>
                      <div>
                        <h3>
                          <p className="more">
                            <ShowMoreText
                              /* Default options */
                              lines={5}
                              more='Show more'
                              less='Show less'
                              className='content-css'
                              anchorClass='btn-more review-more'
                              expanded={false}
                            >
                              {review.comment}
                            </ShowMoreText>
                          </p>
                        </h3>
                      </div>
                      <div className="user-profile d-flex align-items-center">
                        <ul className="rating-star-left">
                          <li>
                            <ul className="rating-star d-flex align-items-center">
                              {review.starRating == "ONE" &&
                                <>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>

                                </>
                              }
                              {review.starRating == "TWO" &&
                                <>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>

                                </>
                              }
                              {review.starRating == "THREE" &&
                                <>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>

                                </>
                              }
                              {review.starRating == "FOUR" &&
                                <>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>

                                </>
                              }
                              {review.starRating == "FIVE" &&
                                <>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                  <li>
                                    <img src={ImgStar} alt="img" />
                                  </li>
                                </>
                              }
                            </ul>
                          </li>
                          <li>
                            <div className="profile-desc">
                              <span className="user-name">{review.reviewer.displayName}</span>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="google-rating  d-flex align-items-center justify-content-center">
                        <img src={Grate} alt="img" />
                      </div>
                    </div>
                  </Col>
                </>
              }
            })}
          </Slider>

          <Slider className="profile-slider" {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
            {postsToShow.map((item, index) => {
              return (
                <div className="profile-item">
                  <img src={item.reviewer.profilePhotoUrl} alt="Image" />
                </div>
              )
            })
            }
          </Slider>

          <div class="btn-wrap d-none d-xl-block google-review-section-btn">
            <a class="btn btn-default" href={props.ReviewURL} target="_blank">{props.CTAText}</a>
            <a href="/about-us/reviews/" class="btn btn-success">{props.CTAView}</a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reviews