import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown/with-html"
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import ImageSlider from '../components/ImageSlider3/imageSlider3'
// import ScrollAnimation from 'react-animate-on-scroll';

const SliderSection = (props)=>{
return (
        <>
      <section className="sec-team">
        <Container>
         {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
          <ReactMarkdown source={props.TeamHeading} escapeHtml={false}/>
          {/* </ScrollAnimation> */}
          {/* <ScrollAnimation animateIn="animate__fadeInUp" animateOnce={true} > */}
          <ImageSlider slideritems={props.data} />
          <div className="btn-wrap text-center">
            <Link to="/about-us/our-people/" className="btn btn-default">meet the full team</Link>
          </div>
          {/* </ScrollAnimation> */}
        </Container>
      </section>
      </>
      )
}
export default SliderSection