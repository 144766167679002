import React from 'react';
import Slider from "react-slick";
import TeamImg from "../../images/placeholder.jpg";
import "slick-carousel/slick/slick.css";
import { Link } from "gatsby";
import YouTube from '@u-wave/react-youtube';
import getVideoId from 'get-video-id';
import $ from 'jquery';
import { VideoPlayButton } from '../icon';
import ImageTransform from "../common/ggfx-client/module/components/image-transform";

const ImageSlider = (props) => {
    if(props.Embed_Video_URL != null && props.Embed_Video_URL != '') {
        var slider_videoid = getVideoId(props.Embed_Video_URL);
      }
      const [showVideo, setShowVideo] = React.useState(false);
      const [videoindex, setVideoindex] = React.useState(false);
      const trackerVideo = (event) => {    
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'Video Play Btn',
          'formType': event,
          'formId': 'Video Play',
          'formName': 'Video',
          'formLabel': 'Video'
        });
    }
      const [isPlay,setPlay] = React.useState(false);
      const playVideo = (video_index, video_id,e) => {  
        setVideoindex(video_index)   
        setShowVideo(true)
        const settings = {
            arrows: false,
        }
      }
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: false,
        centerPadding: '176px',
        responsive: [
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerPadding: '138px',
                }
            },
            {
                breakpoint: 1293,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    centerPadding: '140px',
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerPadding: '163px',
                    dots: true,

                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    centerPadding: '0px',
                    dots: true,
                }
            }
        ]
    };

    return (
        <div className="team-slider">
            <Slider {...settings}>
                {props.slideritems.map((item, index) => {
                    var videoid = item.Embed_Video_URL ? getVideoId(item.Embed_Video_URL):"";
                    let processedImages = JSON.stringify({});
                    if (item?.imagetransforms?.Staff_Image_Transforms) {
                        processedImages = item.imagetransforms.Staff_Image_Transforms;
                    }
                const image_url = item.Staff_Image ? item.Staff_Image.internal.description.replace("File ","").replace('"','').replace('"','') : '';  
                return<>
                <div className="team-wrap text-center">
                    <figure className={`team-img team_img_${index}`}>
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="teams.Staff_Image.officeslider"attr={{ alt: item.Name+' '+item.Designation+ ' - Orlando Reid' }} imagetransformresult={processedImages} id={item.id}/>
                        
                        {item.Embed_Video_URL !=null && item.Embed_Video_URL !='' &&
                        <button className="btn-play"
                            // onClick = { e => {playVideo(`sliderVidID_${index}`)}}
                            onClick = { (e) => {playVideo(index, videoid.id)}}
                        >
                            <VideoPlayButton />
                        </button>
                        }
                        {showVideo && videoindex== index &&
                        <YouTube
                        video={videoid.id}
                        autoplay
                        onEnd={e => {setPlay(false);setShowVideo(false)}}
                        modestBranding={1}
                        onPlaying={trackerVideo(item.Name)}
                        />
                        }
                    </figure>
                    <div className="team-info">
                        <h3>{item.Name}</h3>
                        <span className="job-title">{item.Designation}</span>
                        <ul className="d-flex justify-content-center">
                            <li><a href={`tel:${item.Phone}`}>{item.Phone}</a></li>
                            <li><Link to={`/about-us/our-people/${item.URL}/`}>Bio</Link></li>
                        </ul>
                    </div>
                </div>
                </>
                })}

            </Slider >
        </div>


    )

}

export default ImageSlider;
